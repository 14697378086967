import * as React from "react"
import { SVGProps } from "react"

const ForkAndKnife = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.5215 2.18481C10.9692 2.18481 10.5215 2.63253 10.5215 3.18481L10.5215 7.68481C10.5215 9.06553 9.4022 10.1848 8.02148 10.1848C6.64077 10.1848 5.52148 9.06553 5.52148 7.68481L5.52148 3.18481C5.52148 2.63253 5.07377 2.18482 4.52148 2.18482C3.9692 2.18482 3.52148 2.63253 3.52148 3.18481V7.68481C3.52148 10.1701 5.5362 12.1848 8.02148 12.1848C10.5068 12.1848 12.5215 10.1701 12.5215 7.68481V3.18481C12.5215 2.63253 12.0738 2.18481 11.5215 2.18481Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.02148 10.8152C7.4692 10.8152 7.02148 11.2629 7.02148 11.8152V20.8152C7.02148 21.3675 7.4692 21.8152 8.02148 21.8152C8.57377 21.8152 9.02148 21.3675 9.02148 20.8152V11.8152C9.02148 11.2629 8.57377 10.8152 8.02148 10.8152Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.02148 2.18481C7.4692 2.18481 7.02148 2.63253 7.02148 3.18481V7.45553C7.02148 8.00782 7.4692 8.45553 8.02148 8.45553C8.57377 8.45553 9.02148 8.00782 9.02148 7.45553V3.18481C9.02148 2.63253 8.57377 2.18481 8.02148 2.18481Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.479 13.1848C20.479 13.7371 20.0313 14.1848 19.479 14.1848H15.479C14.9267 14.1848 14.479 13.7371 14.479 13.1848L14.479 6.82118C14.479 4.26058 16.5548 2.18481 19.1154 2.18481C19.8685 2.18481 20.479 2.79534 20.479 3.54845V13.1848ZM18.479 12.1848L18.479 4.26213C17.3304 4.54681 16.479 5.58457 16.479 6.82118L16.479 12.1848H18.479Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.479 10.8152C20.0313 10.8152 20.479 11.2629 20.479 11.8152V20.8152C20.479 21.3675 20.0313 21.8152 19.479 21.8152C18.9267 21.8152 18.479 21.3675 18.479 20.8152V11.8152C18.479 11.2629 18.9267 10.8152 19.479 10.8152Z"
			fill="#7D828C"
		/>
	</svg>
)

export default ForkAndKnife
