import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'
import HeadingTextareaAndAnchors from '../components/_V3/HeadingTextareaAndAnchors'
import HeadingAndTextarea from '../components/_V3/HeadingAndTextarea'
import CardFullWidth from '../components/_V3/CardFullWidth'
import CardListHalfWidth from '../components/_V3/CardListHalfWidth'

import CtaForm from '../pagesSections/index/CtaForm'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import BusinessTypes from '../components/_V2/BusinessTypes'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/capability.module.scss'

import Pres from '../pages-data/_V3/capability/CardFullWidth/assets/capability-terminal.png'
import PresBy from '../pages-data/_V3/capability/CardFullWidth/assets/capability-terminal-BY.png'
import PresKz from '../pages-data/_V3/capability/CardFullWidth/assets/capability-terminal-KZ.png'

import { getData as headingTextareaAndAnchors } from '../pages-data/_V3/capability/HeadingTextareaAndAnchors/data'
import { getHeadingAndTextarea } from '../pages-data/_V3/capability/HeadingAndTextarea/data'
import { getData as getCardFullWidthData } from '../pages-data/_V3/capability/CardFullWidth/data'
import { getData as getCardListHalfWidthData } from '../pages-data/_V3/capability/CardListHalfWidth/data'
import { businessData } from '../pages-data/_V2/common/business-types-compact-white'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageCapability() {
  const localizationContext = useLocalizationContext()
  const cardFullWidth = getCardFullWidthData(localizationContext.locale)
  const cardListHalfWidth = getCardListHalfWidthData(localizationContext.locale)
  const headingAndTextarea = getHeadingAndTextarea(localizationContext.locale)

  const metaTitle = {
    'ru-RU': 'Возможности системы автоматизации Quick Resto – программы учёта в ресторанах и кафе',
    'ru-KZ': 'Возможности системы автоматизации Quick Resto — программы учёта в ресторанах и кафе | Казахстан',
    'kz-KZ': 'Возможности системы автоматизации Quick Resto — программы учёта в ресторанах и кафе | Казахстан',
    'ru-BY': 'Возможности системы автоматизации Quick Resto — программы учёта в ресторанах и кафе | Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}capability/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}capability/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}capability/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}capability/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Кассовая система Quick Resto предоставляет широкий набор инструментов для владельцев ресторанного бизнеса, необходимый для стремительного роста продаж и повышения эффективности работы: складской учёт, программы лояльности, детальные отчёты и аналитика. Зарегистрируйтесь бесплатно.',
    'ru-KZ': 'Кассовая система Quick Resto предоставляет широкий набор инструментов для владельцев ресторанного бизнеса, необходимый для стремительного роста продаж и повышения эффективности работы: складской учёт, программы лояльности, детальные отчёты и аналитика. Зарегистрируйся бесплатно и автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Кассовая система Quick Resto предоставляет широкий набор инструментов для владельцев ресторанного бизнеса, необходимый для стремительного роста продаж и повышения эффективности работы: складской учёт, программы лояльности, детальные отчёты и аналитика. Зарегистрируйся бесплатно и автоматизируй бизнес в Казахстане',
    'ru-BY': 'Кассовая система Quick Resto предоставляет широкий набор инструментов для владельцев ресторанного бизнеса, необходимый для стремительного роста продаж и повышения эффективности работы: складской учёт, программы лояльности, детальные отчёты и аналитика. Зарегистрируйся бесплатно и автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Pres}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.capabilityPage,
      )}
      linkCanonical={currentCanonicalLink}
    >
      <HeadingTextareaAndAnchors fixed data={headingTextareaAndAnchors(localizationContext.locale)} />

      <HeadingAndTextarea
        className={styles.capability__section}
        anchor="anchor-1"
        data={headingAndTextarea.find((content) => content.id === 0)}
      />

      <CardFullWidth data={cardFullWidth.find((content) => content.id === 0)} />

      <CardListHalfWidth
        data={cardListHalfWidth.filter((content) => content.id === 0)}
      />

      <HeadingAndTextarea
        className={styles.capability__section}
        anchor="anchor-2"
        data={headingAndTextarea.find((content) => content.id === 1)}
      />

      <CardFullWidth data={cardFullWidth.find((content) => content.id === 1)} />

      <CardListHalfWidth
        data={cardListHalfWidth.filter((content) => content.id === 1)}
      />

      <HeadingAndTextarea
        className={styles.capability__section}
        anchor="anchor-3"
        data={headingAndTextarea.find((content) => content.id === 2)}
      />

      <CardFullWidth data={cardFullWidth.find((content) => content.id === 2)} />

      <CardListHalfWidth
        data={cardListHalfWidth.filter((content) => content.id === 2)}
      />

      <HeadingAndTextarea
        className={styles.capability__section}
        anchor="anchor-4"
        data={headingAndTextarea.find((content) => content.id === 3)}
      />

      <CardFullWidth data={cardFullWidth.find((content) => content.id === 3)} />

      <CardListHalfWidth
        data={cardListHalfWidth.filter((content) => content.id === 3)}
      />

      <AdditionalServices theme="white" className={pageStyles.pageSection} />

      <CtaForm isWhiteBackground className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessData(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase
        theme="white"
        type="horizontal"
        className={classNames(
				  pageStyles.pageSection,
				  styles.capability__knowledgeBase,
        )}
      />
    </DefaultLayout>
  )
}
