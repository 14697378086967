import React, { useState, useEffect } from "react"
import classNames from "classnames"

import { centerHorizontalNavItem } from "../../../utils/showElemFullyInScrollContainer"

import styles from "./styles.module.scss"

type AnchorsType = {
	url: string // Ссылка
	anchor: {
		icon: JSX.Element // Иконка
		text: JSX.Element | string // Текст ссылки
		styles: string // Стили для якоря
	}
}

interface Props {
	className?: string // Класс
	fixed: boolean // Фиксировать заголовок при скролле?
	data: {
		subheading: string // Доп заголовок
		heading: string // Заголовок
		textarea?: string // Описание
		anchors: [AnchorsType] // Якоря
	}
}

// Компонент списка якорей с заголовоком и описаниям для них
// Пример: /capability/ и /service/
export default function HeadingTextareaAndAnchors(props: Props) {
	const data = props.data || []

	const [selectedAnchor, setSelectedAnchor] = useState(data.anchors[0])

	// Проверка сделал ли пользователь скролл по вертикали для установки position: fixed;
	const [isFixed, setFixed] = useState(false)
	const handleScroll = () => {
		let defaultScrollY = 200
		if (window.innerWidth <= 1319) {
			defaultScrollY = 160
		}

		setFixed(window.scrollY > defaultScrollY)
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const setAnchorPosition = (anchorData: AnchorsType, evt) => {
		centerHorizontalNavItem(
			evt.currentTarget,
			evt.currentTarget.parentNode
		)

		setSelectedAnchor(anchorData)

		const section = document.querySelector(anchorData.url)
		if (section) {
			const topPos =
				section.getBoundingClientRect().top + window.pageYOffset - 100

			window.scrollTo({
				top: topPos,
				behavior: "smooth",
			})

			history.replaceState(null, "", anchorData.url)
		}
	}

	const renderAnchorList = (): JSX.Element => (
		<nav
			className={classNames(styles.anchor__list, {
				// Добавить fixed если включен props и был скролл
				[styles.anchor__listFixed]: props.fixed && isFixed,
			})}
		>
			{data.anchors.map(anchor => (
				<span
					key={anchor.url}
					className={classNames(styles.anchor, anchor.anchor.styles)}
					data-selected={
						typeof window !== "undefined" && window.location.hash
							? window.location.hash === anchor.url
							: anchor === selectedAnchor
					}
					onClick={e => {
						setAnchorPosition(anchor, e)
					}}
				>
					{anchor.anchor.icon}
					<span>{anchor.anchor.text}</span>
				</span>
			))}
		</nav>
	)

	return (
		<>
			<section className={classNames(styles.section, props.className)}>
				<span className={styles.subheading}>{data.subheading}</span>
				<h1 className={styles.heading}>{data.heading}</h1>
				{data.textarea && <p className={styles.textarea}>{data.textarea}</p>}
			</section>
			<section
				className={classNames(
					styles.section,
					styles.noPadding,
					props.className
				)}
			>
				{data.anchors.length > 1 && renderAnchorList()}
			</section>
		</>
	)
}
