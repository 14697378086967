import React from "react"

import styles from "./styles.module.scss"
import classNames from "classnames";
import {Locales} from "../../../../localization/types";


export const getHeadingAndTextarea = (locale :Locales) => {
	if (locale === "kz-KZ") {
		return [
			{
				id: 0,
				heading: "Зал қызметкерлеріне",
				headingType: "secondary",
				textarea: (
					<>
				<span className={styles.tablet}>
					Quick Resto интуитивті түсінікті интерфейсі жұмысты жеделдетеді. <br />
					 Бәрі жылдам - қонақтарды отырғызудан асүй дисплейіндегі техкарталарға дейін.
				</span>
						<span className={styles.mobile}>
					Quick Resto интуитивті түсінікті интерфейсі жұмысты жеделдетеді.
							 Бәрі жылдам - қонақтарды отырғызудан асүй дисплейіндегі техкарталарға дейін.
				</span>
					</>
				),
			},
			{
				id: 1,
				heading: "Мекеме қонақтарына",
				headingType: "secondary",
				textarea: (
					<>
				<span className={styles.tablet}>
					Мобильді қосымшаның, сайттың және адалдық карталары бар бонустық  <br />
					бағдарламаның көмегімен өз мекемеңізді есте қаларлық етіңіз.
				</span>
						<span className={styles.mobile}>
					Мобильді қосымшаның, сайттың және адалдық карталары бар бонустық
					бағдарламаның көмегімен өз мекемеңізді есте қаларлық етіңіз.
				</span>
					</>
				),
			},
			{
				id: 2,
				heading: "Бэк-офистің қызметкерлеріне",
				headingType: "secondary",
				textarea: (
					<>
				<span className={classNames(styles.tablet, styles.text)}>
					Quick Resto – бұл бизнесі дамытуға арналған икемді автоматтандыру жүйесі. <br />
					Экожүйелік шешім - қонақтарға арналған мобильді қосымшадан персоналды басқаруға дейін.
				</span>
						<span className={styles.mobile}>
					Quick Resto – бұл бизнесі дамытуға арналған икемді автоматтандыру жүйесі.
					Экожүйелік шешім - қонақтарға арналған мобильді қосымшадан персоналды басқаруға дейін.
				</span>
					</>
				),
			},
			{
				id: 3,
				heading: "Басшыларға",
				headingType: "secondary",
				textarea: (
					<>
				<span className={styles.tablet}>
					Кез келген нүктеден өз бизнесіңізді басқарыңыз.  <br />
					Quick Resto шешімдері процестердің толық ашықтығын қамтамасыз етеді.
				</span>
						<span className={styles.mobile}>
					Кез келген нүктеден өз бизнесіңізді басқарыңыз.
					Quick Resto шешімдері процестердің толық ашықтығын қамтамасыз етеді.
				</span>
					</>
				),
			},
		]
	}

	return [
		{
			id: 0,
			heading: "Сотрудникам зала",
			headingType: "secondary",
			textarea: (
				<>
				<span className={styles.tablet}>
					Интуитивно понятный интерфейс Quick Resto ускоряет работу.
					<br />
					Всё быстро — от рассадки гостей до техкарт на кухонном дисплее.
				</span>
					<span className={styles.mobile}>
					Интуитивно понятный интерфейс
					<br />
					Quick Resto ускоряет работу.
					<br />
					Всё быстро — от рассадки гостей
					<br />
					до техкарт на кухонном дисплее.
				</span>
				</>
			),
		},
		{
			id: 1,
			heading: "Гостям заведения",
			headingType: "secondary",
			textarea: (
				<>
				<span className={styles.tablet}>
					Сделай своё заведение запоминающимся с помощью мобильного приложения,
					<br />
					сайта и бонусной программы с картами лояльности.
				</span>
					<span className={styles.mobile}>
					Сделай своё заведение запоминающимся
					<br />
					с помощью мобильного приложения,
					<br />
					сайта и бонусной программы
					<br />с картами лояльности.
				</span>
				</>
			),
		},
		{
			id: 2,
			heading: "Сотрудникам бэк-офиса",
			headingType: "secondary",
			textarea: (
				<>
				<span className={classNames(styles.tablet, styles.text)}>
					QuickResto – это гибкая система автоматизации для развития бизнеса.
					<br />
					Экосистемное решение — от мобильного приложения для гостей до
					управления персоналом.
				</span>
					<span className={styles.mobile}>
					QuickResto – это гибкая система
					<br />
					автоматизации для развития бизнеса.
					<br />
					Экосистемное решение — от мобильного
					<br />
					приложения для гостей до управления
					<br />
					персоналом.
				</span>
				</>
			),
		},
		{
			id: 3,
			heading: "Руководителям",
			headingType: "secondary",
			textarea: (
				<>
				<span className={styles.tablet}>
					Управляй своим бизнесом из любой точки.
					<br />
					Решения QuickResto обеспечивают полную прозрачность процессов.
				</span>
					<span className={styles.mobile}>
					Управляй своим бизнесом
					<br />
					из любой точки.
					<br />
					Решения QuickResto обеспечивают
					<br />
					полную прозрачность процессов.
				</span>
				</>
			),
		},
	]
}
export const headingAndTextarea = [
	{
		id: 0,
		heading: "Сотрудникам зала",
		headingType: "secondary",
		textarea: (
			<>
				<span className={styles.tablet}>
					Интуитивно понятный интерфейс Quick Resto ускоряет работу.
					<br />
					Всё быстро — от рассадки гостей до техкарт на кухонном дисплее.
				</span>
				<span className={styles.mobile}>
					Интуитивно понятный интерфейс
					<br />
					Quick Resto ускоряет работу.
					<br />
					Всё быстро — от рассадки гостей
					<br />
					до техкарт на кухонном дисплее.
				</span>
			</>
		),
	},
	{
		id: 1,
		heading: "Гостям заведения",
		headingType: "secondary",
		textarea: (
			<>
				<span className={styles.tablet}>
					Сделай своё заведение запоминающимся с помощью мобильного приложения,
					<br />
					сайта и бонусной программы с картами лояльности.
				</span>
				<span className={styles.mobile}>
					Сделай своё заведение запоминающимся
					<br />
					с помощью мобильного приложения,
					<br />
					сайта и бонусной программы
					<br />с картами лояльности.
				</span>
			</>
		),
	},
	{
		id: 2,
		heading: "Сотрудникам бэк-офиса",
		headingType: "secondary",
		textarea: (
			<>
				<span className={classNames(styles.tablet, styles.text)}>
					QuickResto – это гибкая система автоматизации для развития бизнеса.
					<br />
					Экосистемное решение — от мобильного приложения для гостей до
					управления персоналом.
				</span>
				<span className={styles.mobile}>
					QuickResto – это гибкая система
					<br />
					автоматизации для развития бизнеса.
					<br />
					Экосистемное решение — от мобильного
					<br />
					приложения для гостей до управления
					<br />
					персоналом.
				</span>
			</>
		),
	},
	{
		id: 3,
		heading: "Руководителям",
		headingType: "secondary",
		textarea: (
			<>
				<span className={styles.tablet}>
					Управляй своим бизнесом из любой точки.
					<br />
					Решения QuickResto обеспечивают полную прозрачность процессов.
				</span>
				<span className={styles.mobile}>
					Управляй своим бизнесом
					<br />
					из любой точки.
					<br />
					Решения QuickResto обеспечивают
					<br />
					полную прозрачность процессов.
				</span>
			</>
		),
	},
]
