import * as React from "react"
import { SVGProps } from "react"

const Whistle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.1272 6.97374L8.12724 4.97374M15.7731 6.97374L17.7731 4.97374M12.9501 5.70732L12.9501 2.87891M8.62907 14.1845C8.66438 14.1289 8.70323 14.0758 8.74531 14.0254C9.02144 13.6951 9.43656 13.485 9.90076 13.485C10.7321 13.485 11.4061 14.159 11.4061 14.9903C11.4061 15.453 11.1973 15.867 10.8689 16.1431C10.8156 16.1879 10.7592 16.229 10.7 16.2662M2.39551 15.001H4.30794M14.8996 15.0994C14.8416 17.8105 12.6258 19.9903 9.9008 19.9903C7.13938 19.9903 4.9008 17.7518 4.9008 14.9903C4.9008 12.2289 7.13938 9.99033 9.9008 9.99033C9.93379 9.99033 9.96671 9.99065 9.99955 9.99129L20.9996 9.99033V13.9903L14.8996 15.0994Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default Whistle
