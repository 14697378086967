import React from "react"

import CoffeCup from "../../../../assets/_V2/svg/CoffeCup"
import ForkAndKnife from "../../../../assets/_V2/svg/ForkAndKnife"
import Ninja from "../../../../assets/_V2/svg/Ninja"
import Whistle from "../../../../assets/_V2/svg/Whistle"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			subheading: "Всё готово для работы",
			heading: <>Автоматизация всех процессов <br/>ресторанного бизнеса</>,
			anchors: [
				{
					url: "#anchor-1",
					anchor: {
						icon: <CoffeCup />,
						text: "Сотрудникам зала",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-2",
					anchor: {
						icon: <ForkAndKnife />,
						text: "Гостям заведения",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-3",
					anchor: {
						icon: <Ninja />,
						text: "Сотрудникам бэк-офиса",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-4",
					anchor: {
						icon: <Whistle />,
						text: "Руководителям",
						styles: styles.anchor,
					},
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			subheading: "Бәрі жұмыс үшін дайын",
			heading: "Мейрамханалық бизнестің барлық процестерін автоматтандыру",
			anchors: [
				{
					url: "#anchor-1",
					anchor: {
						icon: <CoffeCup />,
						text: "Зал қызметкерлеріне",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-2",
					anchor: {
						icon: <ForkAndKnife />,
						text: "Мекеме қонақтарына",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-3",
					anchor: {
						icon: <Ninja />,
						text: "Бэк-офистің қызметкерлеріне",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-4",
					anchor: {
						icon: <Whistle />,
						text: "Басшыларға",
						styles: styles.anchor,
					},
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			subheading: "Всё готово для работы",
			heading: "Автоматизация всех процессов ресторанного бизнеса",
			anchors: [
				{
					url: "#anchor-1",
					anchor: {
						icon: <CoffeCup />,
						text: "Сотрудникам зала",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-2",
					anchor: {
						icon: <ForkAndKnife />,
						text: "Гостям заведения",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-3",
					anchor: {
						icon: <Ninja />,
						text: "Сотрудникам бэк-офиса",
						styles: styles.anchor,
					},
				},
				{
					url: "#anchor-4",
					anchor: {
						icon: <Whistle />,
						text: "Руководителям",
						styles: styles.anchor,
					},
				},
			],
		}
	}

	return {
		subheading: "автоматизация бизнеса",
		heading: "Все решения в одной экосистеме",
		anchors: [
			{
				url: "#anchor-1",
				anchor: {
					icon: <CoffeCup />,
					text: "Сотрудникам зала",
					styles: styles.anchor,
				},
			},
			{
				url: "#anchor-2",
				anchor: {
					icon: <ForkAndKnife />,
					text: "Гостям заведения",
					styles: styles.anchor,
				},
			},
			{
				url: "#anchor-3",
				anchor: {
					icon: <Ninja />,
					text: "Сотрудникам бэк-офиса",
					styles: styles.anchor,
				},
			},
			{
				url: "#anchor-4",
				anchor: {
					icon: <Whistle />,
					text: "Руководителям",
					styles: styles.anchor,
				},
			},
		],
	}
}

