/**
 * Центрирование 'табов' для секции с горизонтальным скоролом
 * @param {node} elm - элемент по которому был совершен клик
 * @param {node} container - родитель elm
 * @param {boolean} isForceScroll - включить скролл несмотря на видимость
 */

export function showElemFullyInScrollContainer(elm, container, isForceScroll = false) {
	const elmRect = elm.getBoundingClientRect()
	const containerRect = container.getBoundingClientRect()

	const isButtonVisible =
		elmRect.left >= containerRect.left &&
		elmRect.right <= containerRect.left + containerRect.width

	if (!isButtonVisible || isForceScroll) {
		if (elmRect.left < containerRect.left) {
			container.scrollLeft =
				container.scrollLeft -
				elmRect.right -
				(elmRect.right - elmRect.left) / 2
		} else {
			container.scrollLeft =
				container.scrollLeft + (elmRect.right / 2 - elmRect.width / 4)
		}
	}
}

export function centerHorizontalNavItem(elm, container) {
	const elmRect = elm.getBoundingClientRect()
	const containerRect = container.getBoundingClientRect()

	container.scrollLeft =
		container.scrollLeft +
		elmRect.left -
		(containerRect.width - elmRect.width) / 2
}
