import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				id: 0,
				heading: "Кассовый терминал",
				textarea:
					"Столы и посадка, заказы из зала и на доставку. Все виды оплат, интеграция CRM. Управление кухней и складом. Единый кассовый терминал, который может и больше!",
				btn: {
					text: "Подробнее",
					url: pagesLinks.terminal.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.terminal}
						src="./assets/capability-terminal-BY.png"
						alt="Кассовый терминал quick resto"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 1,
				heading: "Приложение и сайт",
				textarea:
					"Создай приложение и сайт своего заведения за 5 минут. Удобный интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для бизнеса и гостя.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.indexWL.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.appAndSite}
						src="./assets/capability-wl-BY.png"
						alt="Приложение и сайт ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 2,
				heading: "Меню и техкарты",
				textarea:
					"Управляй маржинальностью меню и зарабатывай больше. Экосистема Quick Resto автоматизирует финансовые процессы, контролирует приход и расход, подскажет, когда надо пополнить запасы на складе.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.menu.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.menu}
						src="./assets/capability-menu-BY.png"
						alt="технологические карты для ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 3,
				heading: (
					<span className={styles.qrm__heading}>Quick Resto Manager</span>
				),
				textarea:
					"Мобильное приложение для владельца ресторана, кафе или других точек общепита — следи за продажами, быстро реагируй на сомнительные операции по кассе, анализируй показатели.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.indexQRM.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.qrm}
						src="./assets/capability-qrm-BY.png"
						alt="quick resto manager"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				id: 0,
				heading: "Кассалық терминал",
				textarea:
					"Үстелдер және отырғызу, залдан және жеткізуге тапсырыстар. Барлық төлем түрлері, CRM біріктіру. Асүйді және қойманы басқару. Мүмкіндіктері көп бірыңғай кассалық терминал!",
				btn: {
					text: "Толығырақ",
					url: pagesLinks.terminal.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.terminal}
						src="./assets/capability-terminal-KZ.png"
						alt="Кассовый терминал quick resto"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 1,
				heading: "Қосымша және сайт",
				textarea:
					"Мекемеңіздің қосымшасын және сайтын 1 күнде жасаңыз.   Ыңғайлы интерфейс, жеткізу, өзекті мәзір және бонустық бағдарлама. Бизнес пен қонақ үшін тиімді.",
				btn: {
					text: "Толығырақ",
					url: pagesLinks.indexWL.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.appAndSite}
						src="./assets/capability-wl-KZ.png"
						alt="Приложение и сайт ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 2,
				heading: "Мәзір және техкарталар",
				textarea:
					"Мәзірдің маржиналдығын басқарыңыз және көбірек ақша табыңыз. Quick Resto экожүйесі қаржылық процестерді автоматтандырады, кіріс пен шығысты бақылайды, қоймадағы қорларды қашан олтыру керектігін айтып береді. ",
				btn: {
					text: "Толығырақ",
					url: pagesLinks.menu.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.menu}
						src="./assets/capability-menu-KZ.png"
						alt="технологические карты для ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 3,
				heading: (
					<span className={styles.qrm__heading}>Quick Resto Manager</span>
				),
				textarea:
					"Мейрамхана, кафе немесе басқа тқоғамдық тамақтандыру нүктелеріне арналған мобильді қосымша - сатуды қадағалаңыз, касса бойынша күмәнді операцияларға тез жауап қайтарыңыз, көрсеткіштерді талдаңыз. ",
				btn: {
					text: "Толығырақ",
					url: pagesLinks.indexQRM.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.qrm}
						src="./assets/capability-qrm-KZ.png"
						alt="quick resto manager"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	if (locale === 'ru-KZ') {
		return [
			{
				id: 0,
				heading: "Кассовый терминал",
				textarea:
					"Столы и посадка, заказы из зала и на доставку. Все виды оплат, интеграция CRM. Управление кухней и складом. Единый кассовый терминал, который может и больше!",
				btn: {
					text: "Подробнее",
					url: pagesLinks.terminal.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.terminal}
						src="./assets/capability-terminal-KZ.png"
						alt="Кассовый терминал quick resto"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 1,
				heading: "Приложение и сайт",
				textarea:
					"Создай приложение и сайт своего заведения за 5 минут. Удобный интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для бизнеса и гостя.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.indexWL.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.appAndSite}
						src="./assets/capability-wl-KZ.png"
						alt="Приложение и сайт ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 2,
				heading: "Меню и техкарты",
				textarea:
					"Управляй маржинальностью меню и зарабатывай больше. Экосистема Quick Resto автоматизирует финансовые процессы, контролирует приход и расход, подскажет, когда надо пополнить запасы на складе.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.menu.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.menu}
						src="./assets/capability-menu-KZ.png"
						alt="технологические карты для ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 3,
				heading: (
					<span className={styles.qrm__heading}>Quick Resto Manager</span>
				),
				textarea:
					"Мобильное приложение для владельца ресторана, кафе или других точек общепита — следи за продажами, быстро реагируй на сомнительные операции по кассе, анализируй показатели.",
				btn: {
					text: "Подробнее",
					url: pagesLinks.indexQRM.href,
					useGatsbyLink: true
				},
				mediaContent: (
					<StaticImage
						className={styles.qrm}
						src="./assets/capability-qrm-KZ.png"
						alt="quick resto manager"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	return [
		{
			id: 0,
			heading: "Кассовый терминал",
			textarea:
				"Столы и посадка, заказы из зала и на доставку. Все виды оплат, интеграция CRM. Управление кухней и складом. Единый кассовый терминал, который может и больше!",
			btn: {
				text: "Подробнее",
				url: pagesLinks.terminal.href,
				useGatsbyLink: true
			},
			mediaContent: (
				<StaticImage
					className={styles.terminal}
					src="./assets/capability-terminal.png"
					alt="Кассовый терминал quick resto"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 1,
			heading: "Приложение и сайт",
			textarea:
				"Создай приложение и сайт своего заведения за 5 минут. Удобный интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для бизнеса и гостя.",
			btn: {
				text: "Подробнее",
				url: pagesLinks.indexWL.href,
				useGatsbyLink: true
			},
			mediaContent: (
				<StaticImage
					className={styles.appAndSite}
					src="./assets/capability-wl.png"
					alt="Приложение и сайт ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 2,
			heading: "Меню и техкарты",
			textarea:
				"Управляй маржинальностью меню и зарабатывай больше. Экосистема Quick Resto автоматизирует финансовые процессы, контролирует приход и расход, подскажет, когда надо пополнить запасы на складе.",
			btn: {
				text: "Подробнее",
				url: pagesLinks.menu.href,
				useGatsbyLink: true
			},
			mediaContent: (
				<StaticImage
					className={styles.menu}
					src="./assets/capability-menu.png"
					alt="технологические карты для ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 3,
			heading: (
				<span className={styles.qrm__heading}>Quick Resto Manager</span>
			),
			textarea:
				"Мобильное приложение для владельца ресторана, кафе или других точек общепита — следи за продажами, быстро реагируй на сомнительные операции по кассе, анализируй показатели.",
			btn: {
				text: "Подробнее",
				url: pagesLinks.indexQRM.href,
				useGatsbyLink: true
			},
			mediaContent: (
				<StaticImage
					className={styles.qrm}
					src="./assets/capability-qrm.png"
					alt="quick resto manager"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	]
}
