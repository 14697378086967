import * as React from "react"
import { SVGProps } from "react"

const Ninja = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.5 4.5C7.08172 4.5 3.5 8.08172 3.5 12.5C3.5 16.9183 7.08172 20.5 11.5 20.5C15.9183 20.5 19.5 16.9183 19.5 12.5C19.5 8.08172 15.9183 4.5 11.5 4.5ZM1.5 12.5C1.5 6.97715 5.97715 2.5 11.5 2.5C17.0228 2.5 21.5 6.97715 21.5 12.5C21.5 18.0228 17.0228 22.5 11.5 22.5C5.97715 22.5 1.5 18.0228 1.5 12.5Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.14662 11.2982C4.55563 11.6311 5.10529 12.0435 5.76421 12.4555C7.28877 13.4086 9.31651 14.3085 11.5 14.3085C13.6835 14.3085 15.7112 13.4086 17.2358 12.4555C17.8947 12.0435 18.4444 11.6311 18.8534 11.2982C18.4444 10.9653 17.8947 10.5529 17.2358 10.141C15.7112 9.18782 13.6835 8.28791 11.5 8.28791C9.31651 8.28791 7.28877 9.18782 5.76421 10.141C5.10529 10.5529 4.55563 10.9653 4.14662 11.2982ZM20.3614 11.2982C21.0723 10.5949 21.0718 10.5945 21.0718 10.5945L21.0698 10.5925L21.0659 10.5886L21.0538 10.5765C21.0437 10.5665 21.0298 10.5528 21.0122 10.5357C20.9769 10.5015 20.9266 10.4533 20.8619 10.3933C20.7327 10.2732 20.5459 10.1052 20.3075 9.90532C19.8315 9.50625 19.1451 8.97597 18.2961 8.44514C16.6134 7.39316 14.2105 6.28791 11.5 6.28791C8.78952 6.28791 6.38658 7.39316 4.70396 8.44514C3.85493 8.97597 3.16849 9.50625 2.69253 9.90532C2.45412 10.1052 2.26731 10.2732 2.13808 10.3933C2.07343 10.4533 2.0231 10.5015 1.98782 10.5357C1.97017 10.5528 1.95628 10.5665 1.94625 10.5765L1.93408 10.5886L1.93018 10.5925L1.92879 10.5939C1.92879 10.5939 1.92775 10.5949 2.63866 11.2982L1.92775 10.5949C1.54229 10.9846 1.54229 11.6119 1.92775 12.0015L2.63866 11.2982C1.92775 12.0015 1.92775 12.0015 1.92775 12.0015L1.92879 12.0025L1.93018 12.004L1.93408 12.0079L1.94625 12.02C1.95628 12.0299 1.97017 12.0436 1.98782 12.0607C2.0231 12.095 2.07343 12.1431 2.13808 12.2032C2.26731 12.3232 2.45412 12.4912 2.69253 12.6911C3.16849 13.0902 3.85493 13.6205 4.70396 14.1513C6.38658 15.2033 8.78952 16.3085 11.5 16.3085C14.2105 16.3085 16.6134 15.2033 18.2961 14.1513C19.1451 13.6205 19.8315 13.0902 20.3075 12.6911C20.5459 12.4912 20.7327 12.3232 20.8619 12.2032C20.9266 12.1431 20.9769 12.095 21.0122 12.0607C21.0298 12.0436 21.0437 12.0299 21.0538 12.02L21.0659 12.0079L21.0698 12.004L21.0712 12.0025C21.0712 12.0025 21.0723 12.0015 20.3614 11.2982ZM20.3614 11.2982L21.0723 12.0015C21.4577 11.6119 21.4573 10.9841 21.0718 10.5945L20.3614 11.2982Z"
			fill="#7D828C"
		/>
		<path
			d="M9.60526 11.2982C9.60526 11.8214 9.18111 12.2456 8.65789 12.2456C8.13468 12.2456 7.71053 11.8214 7.71053 11.2982C7.71053 10.775 8.13468 10.3508 8.65789 10.3508C9.18111 10.3508 9.60526 10.775 9.60526 11.2982Z"
			fill="#7D828C"
		/>
		<path
			d="M15.2894 11.2982C15.2894 11.8214 14.8653 12.2456 14.3421 12.2456C13.8188 12.2456 13.3947 11.8214 13.3947 11.2982C13.3947 10.775 13.8188 10.3509 14.3421 10.3509C14.8653 10.3509 15.2894 10.775 15.2894 11.2982Z"
			fill="#7D828C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.5 1.5C19.0523 1.5 19.5 1.94772 19.5 2.5V4.5H21.5C22.0523 4.5 22.5 4.94772 22.5 5.5C22.5 6.05228 22.0523 6.5 21.5 6.5H18.5C17.9477 6.5 17.5 6.05228 17.5 5.5V2.5C17.5 1.94772 17.9477 1.5 18.5 1.5Z"
			fill="#7D828C"
		/>
	</svg>
)

export default Ninja
